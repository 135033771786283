<template>
  <div>
    
    <vue-metamask v-if="pleaseConnectWallet"
            userMessage="msg" 
            @onComplete="onComplete"
        >
    </vue-metamask>

    <template v-if="!pleaseConnectWallet">
      <p>You need an Ethereum wallet to mint a gummy.</p>
      <b-container fluid class="mt-4 mb-4">
        <b-row class="justify-content-center"> 
          <b-button class="mt-4 mb-4" variant="primary" @click="onClickConnectMetaMaskWallet">Connect your MetaMask-Wallet</b-button>
        </b-row>
      </b-container>
    </template>

    <template v-if="pleaseConnectWallet && !isMetamaskChecked">
      <p>You need an Ethereum wallet to mint a gummy.</p>
      <b-container fluid class="mt-4 mb-4">
        <b-row class="justify-content-center"> 
          Check in progress ...
        </b-row>
      </b-container>
    </template>

    <template v-if="isMetamaskChecked">
      <template v-if="!wallet">
      </template>

      <template v-if="wallet.type && wallet.type === 'NO_INSTALL_METAMASK'">
        <p>You need an Ethereum wallet to mint a gummy.</p>
        <b-container v-if="wallet.type && wallet.type === 'NO_INSTALL_METAMASK'" fluid class="mt-4 mb-4">
          <b-row class="justify-content-center"> 
            It seams you have no Metamask wallet installed.
          </b-row>
          <b-row class="justify-content-center"> 
            <b-button class="float-center" variant="primary" @click="onOpenMetaMaskUrl">Download Metamask</b-button>
          </b-row>
        </b-container>
      </template>
      <template v-else-if="wallet.type && wallet.type === 'NO_LOGIN'">
        <p>You need an Ethereum wallet to mint a gummy.</p>
        <b-container fluid class="mt-4 mb-4">
          <b-row class="justify-content-center"> 
            <b-button class="mt-4 mb-4" variant="primary" @click="onClickConnectMetaMaskWallet">Connect your MetaMask-Wallet</b-button>
          </b-row>
        </b-container>
      </template>
      <template v-else-if="wallet.type && wallet.type === 'USER_DENIED_ACCOUNT_AUTHORIZATION'">
        <p>You need an Ethereum wallet to mint a gummy.</p>
        <b-container fluid class="mt-4 mb-4">
          <b-row class="justify-content-center"> 
            Please authorize this web application in your metamask wallet and reload this app.
          </b-row>
        </b-container>
      </template>

      <template v-else-if="wallet.type && isSupportedNetwork">
        <p>You need an Ethereum wallet to mint a gummy.</p>
        <b-container fluid class="mt-4 mb-4">
          <b-row>
            <b-col class="pl-0">Chain: Ethereum</b-col>
          </b-row>
          <b-row>
            <b-col class="pl-0">Network: {{ wallet.type }}</b-col>
          </b-row>
          <b-row>
            <b-col class="pl-0">Address: {{ wallet.metaMaskAddress }}</b-col>
          </b-row>
        </b-container>
      </template>

      <template v-else-if="wallet.type && !isSupportedNetwork">
        <b-row>
          <b-col class="pl-0">You use an supported network. Please switch to Ethereum mainnet.</b-col>
        </b-row>
      </template>
      
    </template>

  </div>
</template>

<script>
  
  import VueMetamask from 'vue-metamask';
  import { getNetworkDef } from '@/utils/network-utils.js'

  export default {
    props: {
      wallet: Object
    },
    components: {
      VueMetamask
    },
    data() {
      return {
        msg: "This is demo net work",
        isSupportedNetwork: false,
        pleaseConnectWallet: false,
        isMetamaskChecked: false
      }
    },
    methods: {
      onOpenMetaMaskUrl() {
          window.open('https://metamask.io/download.html', '_blank').focus();
      },
      onClickConnectMetaMaskWallet() {
        this.pleaseConnectWallet = true
      },
      async onComplete(data) {
        console.log(data);
        const wallet = data;
        this.isMetamaskChecked = true;

        //console.log('data:', data)
        //console.log('web3:', data.web3)

        var selectedNetwork = getNetworkDef(data.netID, data.type)
        this.isSupportedNetwork = selectedNetwork;

        this.$emit('walletChange', { wallet: wallet, network: selectedNetwork });
      }
    },
    mounted() {
      console.log("in mounted!!", this.wallet)
      this.pleaseConnectWallet = this.wallet && this.wallet.type && this.wallet.metaMaskAddress;
    }
  }
</script>