<template>
  <div>
    <p>Stick the gummy on one of your objects and scan the gummy with the app.</p>

    <b-container fluid class="mt-4 mb-4">
      <div class="d-flex p-2 justify-content-center">
        <p v-if="code">Your NFT code: <b>0x{{ code }}</b></p>  
        <p v-else>Please scan your NFT ...</p>
      </div>
    </b-container>

  </div>
</template>

<script>

  import { EventBus } from '@/event-bus';

  export default {
    components: {
    },
    data() {
      return {
        code: null
      }
    },
    methods: {
    },
    mounted() {
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        this.code = pairingDoc.data.code
        if(this.code && this.code.length > 5) {
          this.$emit('gummyScanned');
        }
      })
    }
  }
</script>