<template>
  <b-container v-if="wallet" fluid class="pl-0 ml-0 mt-4 mb-4">
    <p>Yeh! You can start to mint your own NFT! Please check everything again. If you think everything fits, click on Minting.</p>
    <b-row>
      <b-col>Chain: Ethereum</b-col>
    </b-row>
    <b-row>
      <b-col>Network: {{ wallet.type }}</b-col>
    </b-row>
    <b-row>
      <b-col>Address: {{ wallet.metaMaskAddress }}</b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4">NFT-Code: 0x{{ pairingDoc.data.code }}</b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 d-flex p-2 justify-content-center"><img :src="getImagePath()" /></b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 mb-4 d-flex p-2 justify-content-center">{{ description }}</b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    components: {
    },
    props: {
      wallet: {
        type: Object,
        required: true
      },
      pairingDoc: {
        type: Object,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    },
    data() {
      return {
      }
    },
    methods: {
      getImagePath() {
        return `https://api.gummy.link/api/v1/image?path=${this.pairingDoc.data.image}&gummy-api-token=OWSkiVErkyARYONYScHePLEnt`
      }
    }
  }
</script>