<template>
  <div>
    <p>If you don't have our gummy app installed yet go to your online store and search for gummy.link</p>
    <b-container fluid class="mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <div v-if="paired">
          Mobile phone connected.
        </div>
        <div v-else>
          <div class="qrcode pl-4 mb-4">
            <img src="@/assets/gummy-link-app-icon.png" />
          </div>
          <div class="pl-4 mb-4">
            After installation open the gummy app and scan the following qrcode to connect your mobile phone with the web app.
          </div>
          <div class="qrcode">
            <qrcode-vue :value="getPairingRefCode()"></qrcode-vue>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>

  import QrcodeVue from 'qrcode.vue'
  import { EventBus } from '@/event-bus';

  export default {
    components: {
      QrcodeVue
    },
    props: {
      pairingRef: {
        type: String,
        required: true
      },
      pairingState: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        paired: this.pairingState
      }
    },
    methods: {
      getPairingRefCode() {
        return `annftapp:pairingRef:${this.pairingRef}`
      }
    },
    mounted() {
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        this.paired = pairingDoc.data.mappConnected
        if(this.paired) {
          this.$emit('mappConnected');
        }
      })
    }
  }
</script>