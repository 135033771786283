import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueKonva from 'vue-konva'

axios.defaults.headers.common['gummy-api-token'] = 'OWSkiVErkyARYONYScHePLEnt' // for all requests

Vue.prototype.$http = axios;


import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles.scss'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueKonva)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
