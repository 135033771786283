<template>
  <b-container fluid>
    <b-row class="justify-content-center">
     <div class="content-container">
      <h2 class="heading centered">gummy.link NFT Minting Fab</h2>
      <b-container fluid>
        <b-row>
          <b-col></b-col>
          <b-col>
            <div class="float-right">
              <v-stage :config="configKonva">
                <v-layer>
                  <v-circle :config="mappConnected ? configCircleConnected : configCircleDisconnected"></v-circle>
                </v-layer>
              </v-stage>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div v-if="minting == 'preparation'">

          <b-progress :value="progress" variant="success" class="mt-4 mb-2"></b-progress>

          <b-card v-if="current_step==1" class="card-style card-gradient" title="STEP1">
            <connect-wallet v-on:walletChange="onWalletChange" :wallet="wallet"></connect-wallet>
            <b-button v-if="isWalletConnected" class="float-right" variant="primary" @click="onClickNext">Next</b-button>
          </b-card>

          <b-card v-if="current_step==2" class="card-style card-gradient" title="STEP2">
            <pairing-app :pairingRef="pairingRef" :pairingState="mappConnected" v-on:mappConnected="onMappConnected"></pairing-app>
            <b-button class="float-left" variant="secondary" @click="onClickBack">Back</b-button>
            <b-button v-if="mappConnected" class="float-right" variant="primary" @click="onClickNext">Next</b-button>
          </b-card>

          <b-card v-if="current_step==3" class="card-style card-gradient" title="STEP3">
            <scan-code v-on:gummyScanned="onGummyScanned"></scan-code>
            <b-button class="float-left" variant="secondary" @click="onClickBack">Back</b-button>
            <b-button v-if="pairingDoc && pairingDoc.data.code" class="float-right" variant="primary" @click="onClickNext">Next</b-button>
          </b-card>

          <b-card v-if="current_step==4" class="card-style card-gradient" title="STEP4">
            <take-picture-and-meta v-on:descriptionChange="onDescriptionChange"></take-picture-and-meta>
            <b-button class="float-left" variant="secondary" @click="onClickBack">Back</b-button>
            <b-button class="float-right" variant="primary" @click="onClickNext">Next</b-button>
          </b-card>

          <b-card v-if="current_step==5" class="card-style card-gradient" title="STEP5">
            <start-minting :wallet="wallet" :pairingDoc="pairingDoc" :description="description"></start-minting>
            <b-button class="float-left" variant="secondary" @click="onClickBack">Back</b-button>
            <b-button class="float-right" variant="primary" @click="onClickStartMinting">Minting!</b-button>
          </b-card>

        </div>

        <div v-if="minting == 'minting'">

          <p>The minting is now in progress.</p>

          <b-progress :value="progress" variant="success" class="mt-4 mb-2"></b-progress>

          <b-card v-if="current_step > 0" class="card-style card-gradient" title="Minting">
            <div v-if="current_step > 0">
              <p v-if="ipfsHash == null">Pining data on ipfs ...</p>
              <p v-else>IpfsHash: {{ ipfsHash }}</p>
            </div>

            <div v-if="current_step > 1">
              <p v-if="!txHash">Create Transaction</p>
              <p v-else>txHash: {{ txHash }}</p>
            </div>

            <div v-if="current_step > 2">
              <p>Waiting for <a :href="getTxMonitorUrl()" target="_blank">{{ txHash }}</a></p> <p v-if="current_step > 3">State: {{txState}}</p>
            </div>

            <div v-if="current_step > 3">
              <p v-if="txState === 'Success'">It's DONE!!!</p>
              <p v-else>Oh no. Something went wrong! :-(</p>
            </div>

          </b-card>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
  //import VueMetamask from 'vue-metamask';
  import ConnectWallet from './components/steps/ConnectWallet.vue';
  import PairingApp from './components/steps/PairingApp.vue';
  import ScanCode from './components/steps/ScanCode.vue';
  import TakePictureAndMeta from './components/steps/TakePictureAndMeta.vue';
  import StartMinting from './components/steps/StartMinting.vue';
  import { EventBus } from '@/event-bus';
  //import { txReceiptListener } from '@/utils/tx-state-change-listener.js'
  import contract from '@/contracts/GummyNftSC.json'

  export default {
    components: {
      ConnectWallet,
      PairingApp,
      ScanCode,
      TakePictureAndMeta,
      StartMinting
    },
    data() {
      return {
        name: 'BootstrapVue',
        show: true,
        msg: "This is demo net work",
        current_step: 1,
        wsconnection: null,
        pairingRef: null,
        mappConnected: false,
        pairingDoc: null,
        description: "",
        wallet: null,
        network: null,
        pleaseConnectWallet: false,
        minting: "preparation",
        ipfsHash: null,
        mintRef: null,
        txHash: null,
        txDone: null,
        txState: null,
        isWalletConnected: false,
        configKonva: {
          width: 10,
          height: 10
        },
        configCircleConnected: {
          x: 5,
          y: 5,
          radius: 2.5,
          fill: "green",
        },
        configCircleDisconnected: {
          x: 5,
          y: 5,
          radius: 2.5,
          fill: "red",
        }
      }
    },
    watch: {
      show(newVal) {
        console.log('Alert is now ' + (newVal ? 'visible' : 'hidden'))
      },
      pairingDoc: {
        handler() {
          console.log("emit updatePairingDoc event")
          EventBus.$emit('updatePairingDoc', this.pairingDoc)
        },
        deep: true
      }
    },
    computed: {
      progress: function() {
        return Math.round(100 / (this.minting === 'preparation' ? 5 : 4)) * this.current_step;
      }
    },
    methods: {
      toggle() {
        console.log('Toggle button clicked')
        this.show = !this.show
      },
      dismissed() {
        console.log('Alert dismissed')
      },
      getTxMonitorUrl() {
        return `${this.network.monitorUrl}${this.txHash}`
      },
      onComplete(data){
        console.log('data:', data);
      },
      onClickNext: function() {
        this.current_step++;
      },
      onClickBack: function() {
        this.current_step--;
      },
      onClickFirst: function() {
        this.current_step = 1;
      },
      onWalletChange(value) {
        this.wallet = value.wallet
        this.network = value.network
        this.isWalletConnected = this.network && this.wallet.metaMaskAddress && this.wallet.metaMaskAddress.length > 10 ? true : false;
      },
      onMappConnected() {
        this.onClickNext();
      },
      onGummyScanned() {
        this.onClickNext();
      },
      onDescriptionChange(value) {
        this.description = value
      },
      onClickStartMinting() {

        if(!this.network) {
          return;
        }

        this.minting = 'minting'
        this.current_step = 1

        var data = {
            "code": this.pairingDoc.data.code,
            "description": this.description,
            "image": this.pairingDoc.data.image,
            "network": this.network.key
          }

        console.log("data to post", data)

        this.$http({
          method: 'POST',
          url: 'https://api.gummy.link/api/v1/pinFileToIPFS',
          data: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
          }
        })
        .then(resp => {
          console.log(resp.status)
          this.ipfsHash = resp.data.ipfsHash
          this.mintRef = resp.data.mintRef
          this.current_step = 2
          return true
        })
        .then(async val => {
          console.log(val)

          var web3 = this.wallet.web3

          var txCount = await web3.eth.getTransactionCount(this.wallet.metaMaskAddress)
          const gummyNftSc = new web3.eth.Contract(contract.abi, this.network.contract)

          const salting = (id) => {
            const check = 0xfcd01b9;
            var salt = id & check;
            salt >>>= 6;
            salt += 0x2313d;
            return salt;
          }

          const intValue = parseInt(this.pairingDoc.data.code, 16)

          console.log(this.pairingDoc.data.code, intValue, salting(intValue))

          const transaction = {
            'from': this.wallet.metaMaskAddress,
            'to': this.network.contract,
            'nonce': txCount,
            'data': gummyNftSc.methods.mintToken(this.wallet.metaMaskAddress, intValue, salting(intValue)).encodeABI()
          }

          console.log("try to send transaction")

          web3.eth.sendTransaction(transaction, (error, hash) => {
            if(error) {
              return
            }

            this.txHash = hash
            
            this.$http({
              method: 'POST',
              url: 'https://api.gummy.link/api/v1/mintNFT/' + this.mintRef,
              data: JSON.stringify({tx: hash}),
              headers: {
                "content-type": "application/json",
                "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
              }
            });
            
            this.current_step = 3
          })

          //txReceiptListener(this.txHash, web3).then((receipt) => {
          //  if(receipt != null) {
          //    if(receipt.status) {
          //      this.txState = "Success"
          //    } else {
          //      this.txState = "Error"
          //    }
          //  }
          //  this.current_step = 4
          //})
        })
      }
    },
    created() {
      this.$http({
        method: 'POST',
        url: 'https://api.gummy.link/api/v1/pairing/new-challenge',
        json: {},
        headers: {
          "content-type": "application/json",
          "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
        }
      })
      .then(resp => {
        console.log(resp.data)
        this.pairingRef = resp.data.pairingRef
        this.wsconnection = new WebSocket(`wss://api.gummy.link/api/v1/pairing/${this.pairingRef}/dapp?gummy-api-token=OWSkiVErkyARYONYScHePLEnt`)
        this.wsconnection.onmessage = (event) => {
          this.pairingDoc = JSON.parse(event.data)
          this.mappConnected = this.pairingDoc.data.mappConnected
          console.log(JSON.stringify(this.pairingDoc));
        }
        this.wsconnection.onopen = (event) => {
          console.log(event)
        }
      })
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        // always executed
      })
    }
  }
</script>

<style>
  body { padding: 1rem; }
  .content-container { padding-left: 200px; padding-right: 200px; margin-top: 50px }
</style>