<template>
  <div>
    <p>Take a picture with your mobile phone ...</p>

    <b-container v-if="imagePath" fluid class="mt-4 mb-4">
      <div class="d-flex p-2 justify-content-center">
        <img class="object-pic" :src="getImagePath()" />
      </div>
    </b-container>
    
    <div class="mt-4" v-if="imagePath">
    </div>

    <div class="mt-4 mb-4">
      <b-form-textarea
        id="textarea"
        v-model="text"
        placeholder="and write something."
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </div>

  </div>
</template>

<script>

  import { EventBus } from '@/event-bus';

  export default {
    components: {
    },
    data() {
      return {
        imagePath: null,
        text: null
      }
    },
    watch: {
      text() {
        this.$emit('descriptionChange', this.text)
      }
    },
    methods: {
      getImagePath() {
        return `https://api.gummy.link/api/v1/image?path=${this.imagePath}&gummy-api-token=OWSkiVErkyARYONYScHePLEnt`
      }
    },
    mounted() {
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        this.imagePath = pairingDoc.data.image
      })
    }
  }
</script>